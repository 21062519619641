/* LandingPage.css */

.landing-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 80vh;
    padding-right: 58%; /* Adjust as needed */
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }
  
  .background-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
  }
  
  .content-transparent {
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
    width: 80%;
    margin: 0 auto;
  }
  
  .text-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  h1,
  p {
    margin: 0;
  }
  
  .coming-soon {
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
  
  .icon-content {
    text-align: center;
    margin: 0 auto;
  }
  
  .instagram-icon {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  